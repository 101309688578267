.active-row {
  background-color: #c1e7b8;
}

.inactive-row {
  background-color: #f2f2f2;
}

.row {
  cursor: pointer;
}