/* For ID Card */
.id-container {
  width: 300px;
  height: 500px;
  margin: 0 auto;
}

.id-container-head {
  text-align: center;
  padding: 50px auto;
}

.id-container-head img {
  width: 160px;
  height: 80px;
}

.id-container-qr {
  background: rgb(74, 186, 112);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 15px auto;
}

.id-container-qr img {
  margin: 0 auto;
  display: block;
  width: 80px;
  box-shadow: 5px 2px 5px green;
  background: whitesmoke;
}

.id-container-body {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.id-container-body-image {
  margin: 0 auto;
  padding: 0;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: .5px solid #4b97d4;
}

.id-container-body-image img {
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
  box-shadow: 5px 5px 20px #4b97d4;
}

.id-container-body-name-div {
  margin: 0 auto;
  margin-top: 10px;
}

.id-container-body-name-div-policy {
  position: relative;
}

.id-container-body-name-div p {
  margin: 0;
  padding: 0;
}

.id-container-body-name-div p:first-child {
  margin: 5px 0;
  text-align: center;
}

.id-container-body-name-div-name {
  color: #4b97d4;
  font-size: 1.2em;
  font-weight: bolder;
}

.id-container-body-name-div-id {
  font-weight: bolder;
  color: #4b97d4;
}
