.loginPage {
  margin: 0 auto;
  /* margin: 2em auto; */
  display: flex;
  height: max-content;
  width: fit-content;
  height: fit-content;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-content: center;
  /* box-shadow: 5px 5px 5px grey; */
}

.auth-app-page {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-self: flex-start;
  justify-self: center;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-end;
}

/* React Default Style */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form {
  padding: 50px 50px;
  box-shadow: 5px 2px 5px grey;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}