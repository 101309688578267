.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* Adjust based on your gap size */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* Adjust based on your gap size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px; /* Adjust based on your gap size */
}

.status-button {
  position: absolute;
  right: 5px;
  top: 5px;
}

.action-buttons {
  position: absolute;
  /*  top: -50%; */
  left: 20%;
  bottom: -10%; /* Adjust the offset as needed */
  transform: translateY(-100%, 50%);
  display: flex;
   /* flex-direction: column; */
  gap: 10px;
  opacity: 0;
  z-index: 10000000000000000;
  transition: opacity 0.5s ease;
}

/* hsj */
 /* .action-buttons {
   position: absolute;
     top: -50%; 
   left: 20%;
   bottom: -50%;
   transform: translateY(-100%, 50%);
   display: flex;
   gap: 10px;
   opacity: 0;
   z-index: 10000000000000000;
   transition: opacity 0.5s ease;
 } */

.ant-card-hoverable:hover .action-buttons {
  opacity: 1;
  transform: translateY(-110%);
}
.ant-card-cover img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.updated-row {
  background: #e07b83;
  color: #302b2bc9;
}

.updated-row:hover {
  background-color: #e07b8370 !important;
  color: #222020;
}
